import React from "react"
import Layout from "@components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "@components/seo"
import marked from "marked"
import oval1 from "@images/oval.svg"
import oval2 from "@images/oval-2.svg"
import oval3 from "@images/oval-3.svg"
import headlineOverlay from "@images/headline-overlay.svg"
import JwPagination from "@components/pagination"
import placeHolderImg from "@images/placeholderBG.jpg"

// const slugify = require("slugify")
const customLabels = {
  first: <i className="fas fa-angle-double-left d-none" />,
  last: <i className="fas fa-angle-double-right d-none" />,
  previous: <i class="fas fa-angle-left d-none" />,
  next: <i class="fas fa-angle-right d-none" />,
}
class Blogs extends React.Component {
  constructor(props) {
    super(props)

    // an example array of items to be paged

    const filterEvent = props.data.allSanityPost.edges
    // bind the onChangePage method to this React component
    this.onChangePage = this.onChangePage.bind(this)

    // store example items and current page of items in local state
    this.state = {
      filterEvent,
      pageOfItems: [],
    }
  }

  onChangePage(pageOfItems) {
    // update local state with new page of items
    this.setState({ pageOfItems })
  }
  render() {
    console.log("sanitydata", this.props.data)
    const single =
      this.state && this.state.pageOfItems && this.state.pageOfItems[0]

    const posts =
      this.state &&
      this.state.pageOfItems &&
      this.state.pageOfItems.filter(t => t.node.id !== single && single.node.id)
    return (
      <Layout location={this.props.location}>
        <SEO
          title="LeadRoll Blog"
          description="Your secret archive of amazing LinkedIn Lead Generation facts and data points. Thinking about scaling your business to business lead gen efforts? This is a good starting place."
        />
        <Container
          fluid={true}
          className="headline-section"
          style={{ background: "#fff" }}
        >
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-lg-6 headline-content p-relative vertical-align ">
              <div
                className="brandstamp-desktop"
                style={{ padding: "17em 6em 6em 6em" }}
              >
                <h1 className="text-leadblue mb-3">
                  <strong>{single && single.node.title}</strong>
                </h1>
                {single && single.node.excerpt ? (
                  <div
                    className="mb-4 text-secondary"
                    dangerouslySetInnerHTML={{
                      __html: marked(single && single.node.excerpt),
                    }}
                  />
                ) : null}
                <Link
                  className="btn btn-primary btn-blog"
                  to={`/${single && single.node.slug.current}`}
                >
                  READ MORE
                </Link>
              </div>

              <img className="headline-oval" src={oval1} alt="Oval Svg" />
            </div>
            <div
              className="col-lg-6 p-5 headline-img vertical-align"
              style={{
                background: `linear-gradient( 225deg, rgba(95, 20, 105, 0.5046393557422969) 0%, rgba(84, 38, 253, 0.5) 100% ), url(${
                  single &&
                  single.node &&
                  single.node.mainImage &&
                  single.node.mainImage.asset &&
                  single.node.mainImage.asset.fluid &&
                  single.node.mainImage.asset.fluid.src
                    ? single.node.mainImage.asset.fluid.src
                    : placeHolderImg
                })`,
                backgroundPosition: "center",
                backgroundSize: "cover !important",
              }}
            >
              <img
                className="headline-overlay"
                src={headlineOverlay}
                alt="Headline Overlay"
              />
              {/* <div className="brandstamp d-none-desktop">
                <h1 className="text-leadblue mb-3 text-white">
                  <strong>{single && single.node.title}</strong>
                </h1>
                {single && single.node.excerpt ? (
                  <div
                    className="mb-4 text-secondary"
                    dangerouslySetInnerHTML={{
                      __html: marked(single && single.node.excerpt),
                    }}
                  />
                ) : null}
                <Link
                  className="btn btn-primary btn-blog"
                  to={`/${single && single.node.slug.current}`}
                >
                  READ MORE
                </Link>
              </div> */}
            </div>
          </div>
        </Container>
        <div
          style={{ background: "#fff" }}
          className="container-fluid blog-body p-relative"
        >
          <div className="container">
            <h2 className="text-center text-leadblue">Our Latest Blog</h2>
            <div className="row">
              {posts.map(post => (
                <div className="col-md-4 mt-5" key={post.id}>
                  <div className="blog-container h-100">
                    <Link to={`/${post.node.slug.current}`}>
                      <div
                        className="blog-img blog-bg-1"
                        style={{
                          background: `linear-gradient( 268deg, rgba(95, 20, 105, 0.5046393557422969) 0%, rgba(84, 38, 253, 0.5) 100% ),url(${
                            post &&
                            post.node &&
                            post.node.mainImage &&
                            post.node.mainImage.asset &&
                            post.node.mainImage.asset.fluid &&
                            post.node.mainImage.asset.fluid.src
                              ? post.node.mainImage.asset.fluid.src
                              : placeHolderImg
                          })`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </Link>

                    <div className="blog-head">
                      <h5 className="text-leadblue">
                        <strong>{post.node.title}</strong>
                      </h5>
                      {post.node.excerpt ? (
                        <p className="mt-3">{post.node.excerpt}</p>
                      ) : null}

                      <div className="row mt-4">
                        <div className="col-6">
                          <p>
                            <i className="fa fa-calendar text-leadblue"> </i>{" "}
                            {post.node.publishedAt
                              ? post.node.publishedAt
                              : post.node._createdAt}
                          </p>
                        </div>
                        <div className="col-6">
                          <Link
                            className="btn btn-primary btn-blog w-100"
                            to={`/${post.node.slug.current}`}
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <img className="oval-svg" src={oval1} alt="Oval svg" />
              <img className="oval-2" src={oval2} alt="Oval svg" />
              <img className="oval-3" src={oval3} alt="Oval svg" />
            </div>
            <div
              className="mt-5 event-paginate float-left"
              style={{ zIndex: 4, position: "relative" }}
            >
              <JwPagination
                items={this.state.filterEvent}
                onChangePage={this.onChangePage}
                pageSize={12}
                labels={customLabels}
              />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityPost(
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: "null" } } }
    ) {
      edges {
        node {
          id
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          _createdAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`
